<template>
  <div>
    <Header />
    <router-view/>
    <!-- <keep-alive>
      <router-view></router-view>
    </keep-alive> -->
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/directStoreLayout/header.vue";
import Footer from "@/components/directStoreLayout/footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="less" scoped>
</style>